import React, { useState, useEffect, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { UsersListType } from "../types/adminTypes";
import { UsersListContext } from "../context/UsersListContext";

interface ChartData {
    categories: string[];
    seriesData: number[];
}

// Function to generate past months dynamically
const getPastMonths = (totalMonths: number) => {
    const months: string[] = [];
    const currentDate = new Date();

    for (let i = totalMonths - 1; i >= 0; i--) {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
        months.push(date.toLocaleString("default", { month: "short", year: "2-digit" })); // Example: "Jan 24"
    }

    return months;
};

// Function to process user data
const processUserData = (users: UsersListType[], totalMonths: number) => {
    const allMonths = getPastMonths(totalMonths);
    const groupSize = Math.ceil(totalMonths / 12); // Adjust scale if more than 12 months
    const monthCounts: { [key: string]: number } = {};

    // Initialize counts for all months
    allMonths.forEach((month) => (monthCounts[month] = 0));

    users.forEach((user) => {
        if (user.createdAt?.seconds) {
            const date = new Date(user.createdAt.seconds * 1000);
            const monthYear = date.toLocaleString("default", { month: "short", year: "2-digit" });

            if (monthCounts.hasOwnProperty(monthYear)) {
                monthCounts[monthYear]++;
            }
        }
    });

    // Group data if more than 12 months
    const groupedCategories: string[] = [];
    const groupedSeriesData: number[] = [];

    for (let i = 0; i < allMonths.length; i += groupSize) {
        const group = allMonths.slice(i, i + groupSize);
        const groupLabel = group.length > 1 ? `${group[0]} - ${group[group.length - 1]}` : group[0];
        groupedCategories.push(groupLabel);

        const sum = group.reduce((acc, month) => acc + (monthCounts[month] || 0), 0);
        groupedSeriesData.push(sum);
    }

    return { categories: groupedCategories, seriesData: groupedSeriesData };
};

const UserVsMonthChart: React.FC = () => {
    const { usersList } = useContext(UsersListContext);
    const [monthsToShow, setMonthsToShow] = useState<number>(12);
    const [chartData, setChartData] = useState<ChartData>({ categories: [], seriesData: [] });

    useEffect(() => {
        if (usersList?.length) {
            setChartData(processUserData(usersList, monthsToShow));
        }
    }, [usersList, monthsToShow]);

    const options: ApexOptions = {
        legend: { show: false, position: "top", horizontalAlign: "left" },
        colors: ["#3C50E0"],
        chart: {
            fontFamily: "Satoshi, sans-serif",
            height: 400,
            type: "bar",
            toolbar: { show: false },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                borderRadius: 4,
                dataLabels: { position: "top" },
            },
        },
        grid: { xaxis: { lines: { show: true } }, yaxis: { lines: { show: true } } },
        dataLabels: {
            enabled: true,
            style: {
                colors: ["#3C50E0"],
                fontSize: "12px",
                fontWeight: "bold",
            },
            offsetX: 10,
            formatter: (val) => {
                if (Array.isArray(val)) {
                    return val[0] > 0 ? val[0].toString() : "";
                } else if (typeof val === "number") {
                    return val > 0 ? val.toString() : "";
                }
                return "";
            },
        },
        xaxis: {
            categories: chartData.categories,
            title: { text: "Number of Users" },
        },
        yaxis: {
            title: { text: "Months" },
        },
    };

    return (
        <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 mt-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
            <div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
                <div className="flex w-full flex-wrap gap-3 sm:gap-5">
                    <div className="flex min-w-100">
                        <span className="mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border border-primary">
                            <span className="block h-2.5 w-full max-w-2.5 rounded-full bg-primary"></span>
                        </span>
                        <div className="w-full">
                            <p className="font-semibold text-primary">User Registrations</p>
                            <p className="text-sm font-medium">Last {monthsToShow} Months</p>
                        </div>
                    </div>
                </div>
                <div>
                    <input
                        type="number"
                        min="1"
                        max="60"
                        value={monthsToShow}
                        onChange={(e) => setMonthsToShow(Math.min(Math.max(Number(e.target.value), 1), 60))}
                        className="w-24 border border-black rounded px-2 py-1 text-sm text-black"
                    />
                </div>
            </div>
            <div id="chartOne" className="-ml-5">
                <ReactApexChart options={options} series={[{ name: "Users", data: chartData.seriesData }]} type="bar" height={400} />
            </div>
        </div>
    );
};

export default UserVsMonthChart;
