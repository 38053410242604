import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../common/Loader/Loader';
import { checkAdmin } from '../service/api';

function ProtectedRoute({ children }: { children: JSX.Element }) {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
      } else {
        try {
          const response = await checkAdmin({ token });
          if (response.success) {
            setIsAuthenticated(true);
          } else {
            navigate('/login');
          }
        } catch (err) {
          console.error(err);
          navigate('/login');
        }
      }
      setLoading(false);
    };

    checkAuthentication();
  }, [navigate]);

  // if (loading) return <Loader />;

  return isAuthenticated ? children : null;
}

export default ProtectedRoute;
