import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import React, { useEffect } from 'react';
import HomeImage from './HomeImage';
import HomeDesc from './HomeDesc';
import ReactGA from 'react-ga'

const Home = () => {
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname);
    },[])
    return (
        <>
            <Breadcrumb pageName="Home" />
            <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                <HomeImage />

                <HomeDesc />

            </div>

        </>
    );
};

export default Home;
