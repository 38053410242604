import { UserAnalyticsType } from '../types/userAnalyticsTypes';
import React, { ReactNode, useState, createContext, FC } from 'react';

// Define the type for the context

interface UsersAnalyticsContextProps {
    usersAnalytics: UserAnalyticsType[];
    setUsersAnalytics: React.Dispatch<React.SetStateAction<UserAnalyticsType[]>>;
}

// Provide a default value for the context
const defaultContext: UsersAnalyticsContextProps = {
    usersAnalytics: [],
    setUsersAnalytics: () => { }, // No-op function
};

// Create the context with a default value
export const UsersAnalyticsContext = createContext<UsersAnalyticsContextProps>(defaultContext);

interface UsersAnalyticsProviderProps {
    children: ReactNode;
}

// Define the UsersAnalyticsProvider component
const UsersAnalyticsProvider: FC<UsersAnalyticsProviderProps> = ({ children }) => {
    const [usersAnalytics, setUsersAnalytics] = useState<UserAnalyticsType[]>([]);

    return (
        <UsersAnalyticsContext.Provider value={{ usersAnalytics, setUsersAnalytics }}>
            {children}
        </UsersAnalyticsContext.Provider>
    );
};

export default UsersAnalyticsProvider;
