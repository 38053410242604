import React, { useContext, useState, useEffect } from "react";
import UserActions from "./UserActions";
import { UsersListContext } from "../../context/UsersListContext";
import FilterIcon from "../../images/icon/filter.svg";
import AdminFilterModal from "../../filters/AdminFilterModal";

const UsersList = () => {
    const { usersList } = useContext(UsersListContext);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [filteredUsersList, setFilteredUsersList] = useState(usersList);

    // Sync filtered list with the original usersList when it changes
    useEffect(() => {
        setFilteredUsersList(usersList);
    }, [usersList]);

    return (
        <div className="mx-auto max-w-screen-2xl py-4 md:py-6 2xl:py-10">
            <div className="w-full overflow-x-auto"> {/* Enable horizontal scroll */}
                <div className="min-w-max"> {/* Ensure table maintains width */}

                    {/* Table Header */}
                    <div className="grid grid-cols-12 items-center rounded-t-[10px] bg-primary px-5 py-4 lg:px-7.5 2xl:px-11">
                        {/* Filter Icon */}
                        <div className="col-span-1 flex items-center min-w-[50px]">
                            <img
                                src={FilterIcon}
                                alt="Filter"
                                className="cursor-pointer"
                                onClick={() => setIsFilterOpen(!isFilterOpen)}
                            />
                        </div>

                        {/* Email Header */}
                        <div className="col-span-6 flex items-center min-w-[200px] whitespace-nowrap">
                            <h5 className="font-large text-white">Email</h5>
                        </div>

                        {/* Joined Date Header */}
                        <div className="col-span-5 flex items-center min-w-[150px] whitespace-nowrap">
                            <h5 className="font-large text-white">Joined Date</h5>
                        </div>
                    </div>

                    {/* Table Body with Scrollable Rows */}
                    <div className="bg-white dark:bg-boxdark rounded-b-[10px]">
                        <div className="max-h-[600px] overflow-y-auto custom-scrollbar"> {/* Enable vertical scroll */}
                            {filteredUsersList.map((row, index) => (
                                <UserActions key={row.id} index={index} row={row} />
                            ))}
                        </div>
                    </div>

                </div>
            </div>

            {/* Filter Modal */}
            <AdminFilterModal 
                isOpen={isFilterOpen} 
                onClose={() => setIsFilterOpen(false)} 
                setFilteredUsersList={setFilteredUsersList} 
            />
        </div>
    );
};

export default UsersList;
