import React, { useState, useEffect, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { UsersAnalyticsContext } from "../context/UsersAnalyticsContext";

interface ChartData {
    categories: number[];
    seriesData: number[];
    userMapping: Record<number, { email: string; totalOpens: number }>;
}

const processUserAnalytics = (usersAnalytics: any[]) => {
    const sortedUsers = usersAnalytics.sort((a, b) => b.totalOpens - a.totalOpens).slice(0, 6);
    return {
        categories: sortedUsers.map((_, index) => index + 1), // Y-axis shows rankings (1, 2, 3...)
        seriesData: sortedUsers.map((user) => user.totalOpens),
        userMapping: Object.fromEntries(
            sortedUsers.map((user, index) => [index + 1, { email: user.id, totalOpens: user.totalOpens }])
        ) // Map ranking numbers to user data
    };
};

const HighestActiveUsersChart: React.FC = () => {
    const { usersAnalytics } = useContext(UsersAnalyticsContext);
    const [chartData, setChartData] = useState<ChartData>({ categories: [], seriesData: [], userMapping: {} });

    useEffect(() => {
        if (usersAnalytics?.length) {
            setChartData(processUserAnalytics(usersAnalytics));
        }
    }, [usersAnalytics]);

    const options: ApexOptions = {
        legend: { show: false, position: "top", horizontalAlign: "left" },
        colors: ["#3C50E0"],
        chart: {
            fontFamily: "Satoshi, sans-serif",
            height: 400,
            type: "bar",
            toolbar: { show: false },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                borderRadius: 4,
                dataLabels: { position: "top" },
            },
        },
        grid: { xaxis: { lines: { show: true } }, yaxis: { lines: { show: true } } },
        dataLabels: {
            enabled: true,
            style: {
                colors: ["#3C50E0"],
                fontSize: "12px",
                fontWeight: "bold",
            },
            offsetX: 10,
        },
        xaxis: {
            categories: chartData.categories,
            title: { text: "Number of Times Opened" },
        },
        yaxis: {
            title: { text: "Rank" }, // Only ranking numbers appear on Y-axis
            labels: {
                formatter: (value) => `#${value}` // Ensures numeric ranking display
            }
        },
        tooltip: {
            y: {
                formatter: (value, { dataPointIndex }) => {
                    const user = chartData.userMapping[chartData.categories[dataPointIndex]];
                    return user ? `${user.email} - ${user.totalOpens} Opens` : "Unknown User";
                }
            }
        }
    };

    return (
        <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 mt-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
            <div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
                <div className="flex w-full flex-wrap gap-3 sm:gap-5">
                    <div className="flex min-w-100">
                        <span className="mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border border-primary">
                            <span className="block h-2.5 w-full max-w-2.5 rounded-full bg-primary"></span>
                        </span>
                        <div className="w-full">
                            <p className="font-semibold text-primary">Highest Active Users</p>
                            <p className="text-sm font-medium">By number of times opened</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="chartOne" className="-ml-5">
                <ReactApexChart options={options} series={[{ name: "Opens", data: chartData.seriesData }]} type="bar" height={400} />
            </div>
        </div>
    );
};

export default HighestActiveUsersChart;
