import { getAllData } from "../../service/api";
import { UserListOrderType, UsersListType } from "../../types/adminTypes";

export const sortUsers = (usersList: UsersListType[]): UsersListType[] => {

    // Sort usersList based on createdAt.seconds (most recent first)
    const usersList_updated: UsersListType[] = usersList.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

    return usersList_updated ;
};
