import React, { useEffect, useState } from 'react';
import { UsersListType } from '../../types/adminTypes';
import { formatToIST } from '../../conversion/DateConversion';
// import ChartOne from '../../components/Charts/ChartOne';
// import ChartThree from '../../components/Charts/ChartThree';
// import ChartTwo from '../../components/Charts/ChartTwo';
// import ChatCard from '../../components/Chat/ChatCard';
// import MapOne from '../../components/Maps/MapOne';
// import TableOne from '../../components/Tables/TableOne';

interface UserActionsProps {
    index: number;
    row: UsersListType
}

const UserActions: React.FC<UserActionsProps> = ({ row, index }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const closeDropdown = () => {
        setIsOpen(false);
    };
    return (
        <div
            key={index}
            className="grid grid-cols-12 border-t border-[#EEEEEE] px-5 py-4 dark:border-strokedark lg:px-7.5 2xl:px-11"
        >
            <div className="col-span-1">
                <p className="text-[#637381] dark:text-bodydark">{index + 1}</p>
            </div>
            <div className="col-span-6">
                <p className="text-[#637381] dark:text-bodydark">{row.email}</p>
            </div>
            <div className="col-span-5">
                <p className="text-[#637381] dark:text-bodydark">{formatToIST(row.createdAt)}</p>
            </div>
            {/* <div className="col-span-2">
                <p className="text-[#637381] dark:text-bodydark">{row.name}</p>
            </div> */}
            {/* <div className="col-span-2 relative">
                <div>
                    <button
                        onClick={toggleDropdown}
                        className="float-right inline-flex items-center gap-1.5 rounded-md  py-1.5 text-sm text-black shadow-11 hover:text-primary dark:bg-meta-4 dark:text-white dark:shadow-none"
                    >
                        Action
                        <svg
                            className="fill-current"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.00039 11.4C7.85039 11.4 7.72539 11.35 7.60039 11.25L1.85039 5.60005C1.62539 5.37505 1.62539 5.02505 1.85039 4.80005C2.07539 4.57505 2.42539 4.57505 2.65039 4.80005L8.00039 10.025L13.3504 4.75005C13.5754 4.52505 13.9254 4.52505 14.1504 4.75005C14.3754 4.97505 14.3754 5.32505 14.1504 5.55005L8.40039 11.2C8.27539 11.325 8.15039 11.4 8.00039 11.4Z"
                                fill=""
                            ></path>
                        </svg>
                    </button>
                    {isOpen && (
                        <div
                            onMouseLeave={closeDropdown}
                            className="absolute right-0 top-full z-1 mt-1 w-full max-w-39.5 rounded-[5px] bg-white py-2.5 shadow-12 dark:bg-boxdark"
                        >
                            <button className="flex w-full px-4 py-2 text-sm hover:bg-whiter hover:text-primary dark:hover:bg-meta-4">
                                Edit
                            </button>
                            <button className="flex w-full px-4 py-2 text-sm hover:bg-whiter hover:text-primary dark:hover:bg-meta-4">
                                Delete
                            </button>
                            <button className="flex w-full px-4 py-2 text-sm hover:bg-whiter hover:text-primary dark:hover:bg-meta-4">
                                Details
                            </button>
                        </div>
                    )}
                </div>
            </div> */}
        </div>
    );
};

export default UserActions;
