import axios from 'axios';

const url = process.env.REACT_APP_SERVER_API;

export const checkAdmin = async (token: any) => {
  try {
    const response = await axios.post(`${url}/checkAdmin`, token);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const adminLogin = async (login_payload: { [key: string]: string }) => {
  try {
    const reponse = await axios.post(`${url}/adminLogin`, login_payload);
    return reponse.data;
  } catch (error) {
    console.log(error);
  }
};
export const getAllData = async () => {
  try {
    const response = await axios.get(`${url}/getAllData`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllUserAnalytics = async () => {
  try {
    const response = await axios.get(`${url}/getUserAnalytics`);
    console.log(response.data)
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const redirectToCheckout = async (order_payload: any) => {
  try {
    const response = await axios.post(
      `${url}/redirectToCheckout`,
      order_payload,
    );
    return response.data.paymentSessionID;
  } catch (error) {
    console.log('Error while saving user', error);
  }
};

export const increaseDownload = async () => {
  try {
    await axios.post(`${url}/analytics/downloadCount`, {
      event: 'downloadButton',
      source: 'Header Download Button',
    });
  } catch (error) {}
};
