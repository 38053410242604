import React, { useState, useContext } from "react";
import { UsersListContext } from "../context/UsersListContext";
import Loader from "../common/Loader/Loader";

interface AdminFilterModalProps {
    isOpen: boolean;
    onClose: () => void;
    setFilteredUsersList: React.Dispatch<React.SetStateAction<any[]>>;
}

const AdminFilterModal: React.FC<AdminFilterModalProps> = ({ isOpen, onClose, setFilteredUsersList }) => {
    const { usersList } = useContext(UsersListContext);
    const defaultFilters = {
        emailType: "",
        startDate: "",
        endDate: "",
        subscriptionStatus: "",
        sortBy: "joiningDate",
        sortOrder: "desc",
    };

    const [filters, setFilters] = useState(defaultFilters);
    const [isLoading, setIsLoading] = useState(false);

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    const applyFilters = () => {
        setIsLoading(true);
        setTimeout(() => {
            let filteredUsers = [...usersList];

            if (filters.emailType) {
                filteredUsers = filteredUsers.filter(user =>
                    filters.emailType === "gmail"
                        ? user.email.includes("@gmail.com")
                        : !user.email.includes("@gmail.com")
                );
            }

            if (filters.startDate || filters.endDate) {
                filteredUsers = filteredUsers.filter(user => {
                    const userDate = new Date(user.createdAt.seconds * 1000);
                    const start = filters.startDate ? new Date(filters.startDate) : null;
                    const end = filters.endDate ? new Date(filters.endDate) : null;

                    return (!start || userDate >= start) && (!end || userDate <= end);
                });
            }

            if (filters.sortBy && filters.sortOrder) {
                filteredUsers = filteredUsers.sort((a, b) => {
                    if (filters.sortBy === "joiningDate") {
                        const dateA = new Date(a.createdAt.seconds * 1000).getTime();
                        const dateB = new Date(b.createdAt.seconds * 1000).getTime();
                        return filters.sortOrder === "asc" ? dateA - dateB : dateB - dateA;
                    } else if (filters.sortBy === "email") {
                        return filters.sortOrder === "asc"
                            ? a.email.localeCompare(b.email)
                            : b.email.localeCompare(a.email);
                    }
                    return 0;
                });
            }

            setFilteredUsersList(filteredUsers);
            setIsLoading(false);
            onClose();
        }, 1000);
    };

    const resetFilters = () => {
        setIsLoading(true);
        setTimeout(() => {
            setFilters(defaultFilters);
            setFilteredUsersList(usersList);
            setIsLoading(false);
        }, 1000);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black/90 px-4 py-5">
            <div className="w-full max-w-lg rounded-lg bg-white px-8 py-10 dark:bg-boxdark">
                <h3 className="pb-4 text-xl font-bold text-black dark:text-white text-center">
                    Admin Filters
                </h3>

                {isLoading ? (
                    <Loader fullScreen={false} />
                ) : (
                    <>
                        <div className="mb-5">
                            <label className="block font-medium text-gray-700 dark:text-white mb-2">
                                Filter by Email Type:
                            </label>
                            <select
                                name="emailType"
                                className="w-full p-3 border rounded-md dark:bg-boxdark dark:text-white"
                                onChange={handleFilterChange}
                                value={filters.emailType}
                            >
                                <option value="">All</option>
                                <option value="gmail">Gmail</option>
                                <option value="organization">Organization</option>
                            </select>
                        </div>

                        <div className="mb-5">
                            <label className="block font-medium text-gray-700 dark:text-white mb-2">
                                Joining Date Range:
                            </label>
                            <div className="flex space-x-2">
                                <input
                                    type="date"
                                    name="startDate"
                                    className="w-1/2 p-3 border rounded-md dark:bg-boxdark dark:text-white"
                                    onChange={handleFilterChange}
                                    value={filters.startDate}
                                />
                                <input
                                    type="date"
                                    name="endDate"
                                    className="w-1/2 p-3 border rounded-md dark:bg-boxdark dark:text-white"
                                    onChange={handleFilterChange}
                                    value={filters.endDate}
                                />
                            </div>
                        </div>

                        <div className="mb-5">
                            <label className="block font-medium text-gray-700 dark:text-white mb-2">
                                Sort By:
                            </label>
                            <div className="flex space-x-2">
                                <select
                                    name="sortBy"
                                    className="w-1/2 p-3 border rounded-md dark:bg-boxdark dark:text-white"
                                    onChange={handleFilterChange}
                                    value={filters.sortBy}
                                >
                                    <option value="">None</option>
                                    <option value="joiningDate">Joining Date</option>
                                    <option value="email">Email</option>
                                </select>

                                <select
                                    name="sortOrder"
                                    className="w-1/2 p-3 border rounded-md dark:bg-boxdark dark:text-white"
                                    onChange={handleFilterChange}
                                    value={filters.sortOrder}
                                >
                                    <option value="">Order</option>
                                    <option value="asc">Ascending</option>
                                    <option value="desc">Descending</option>
                                </select>
                            </div>
                        </div>
                    </>
                )}

                {/* Buttons Section with Improved Spacing */}
                <div className="flex justify-end space-x-4 mt-6">
                    <button
                        onClick={resetFilters}
                        className="px-6 py-3 rounded-md border bg-gray-200 text-black transition hover:bg-gray-300"
                    >
                        Reset
                    </button>

                    <button
                        onClick={onClose}
                        className="px-6 py-3 rounded-md border bg-gray-300 text-black transition hover:bg-gray-400"
                    >
                        Cancel
                    </button>

                    <button
                        onClick={applyFilters}
                        className="px-6 py-3 rounded-md border bg-blue-600 text-white transition hover:bg-blue-700"
                    >
                        Apply Filters
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AdminFilterModal;
