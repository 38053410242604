export const formatToIST = (createdAt: { seconds: number; nanoseconds: number }) => {
    const date = new Date(createdAt.seconds * 1000); // Convert seconds to milliseconds
    return date.toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata",
        hour: "2-digit",
        minute: "2-digit",
        year: "numeric",
        month: "short",
        day: "2-digit",
    });
};

